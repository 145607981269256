$primaryblue: #151c47;
$primarygrey: #BFBFBF;

body {
  font-family: 'Source Serif 4', sans-serif;
  margin: 0;
  padding: 0;
}

.who {
  display: flex;
  background-color: $primaryblue;
  height: 100vh;


  @media screen and (max-width: 850px) {
    height: 100vh;
  }
}
.left-side-who {
  width: 50%;
  margin: 20px;
  background-image: url(./images/Fray-Harvey-LOGO-GnB.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 850px) {
    display: none;
  }
}

.right-side-who {
  width: 40%;
  margin: 20px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  

  @media screen and (max-width: 850px) {
    width: 80%;
  }
}

h1 {
  color: $primarygrey;
  font-size: 4vw;
  margin: 0;
  width: 100%;
}

.underline {
  height: 1%;
  width: 100%;
  background-color: $primarygrey;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-box-who {
  color: $primarygrey;
  width: 100%;
  font-size: 1.5vw;


  @media screen and (max-width: 850px) {
    font-size: 4vw;
  }
}


.what {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: $primarygrey;
  height: 100vh;
  
  @media screen and (max-width: 850px) {
    flex-direction: column;
    height: 120vh;
  }
}

.box-one-what {
  width: 30%;
  height: 50%;
  margin: 20px;
  border: 2px solid $primaryblue;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
  font-size: 1vw;
  
  @media screen and (max-width: 850px) {
    margin-top: 85px;
    margin-bottom: 20px;
    width: 80%;
    height: 30%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url(./images/case-studies.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 2.5vw;
  }
  @media screen and (max-width: 400px) {
    margin-top: 85px;
    margin-bottom: 20px;
    width: 80%;
    height: 30%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url(./images/case-studies.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 2vh;
  }
  .image-one{
    height: 50%;
    background-image: url(./images/case-studies.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 5px;
    

    @media screen and (max-width: 850px) {
      display: none;
    }
  }
  &:hover {
    border: 4px solid $primaryblue;
    margin: 18px;
  }
}
.box-two-what {
  width: 30%;
  height: 50%;
  margin: 20px;
  border: 2px solid $primaryblue;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
  font-size: 1vw;
  
  @media screen and (max-width: 850px) {
    width: 80%;
    height: 30%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url(./images/marketing-coll.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 2.5vw;
  }
  @media screen and (max-width: 400px) {
    width: 80%;
    height: 30%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url(./images/marketing-coll.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 2vh;
  }
  .image-two{
    height: 50%;
    background-image: url(./images/marketing-coll.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 5px;

    @media screen and (max-width: 850px) {
      display: none;
    }
  }
  &:hover {
    border: 4px solid $primaryblue;
    margin: 18px;
  }
}
.box-three-what {
  width: 30%;
  height: 50%;
  margin: 20px;
  border: 2px solid $primaryblue;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
  font-size: 1vw;

  @media screen and (max-width: 850px) {
    width: 80%;
    height: 30%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url(./images/content-writing.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 2.5vw;
  }
  @media screen and (max-width: 400px) {
    width: 80%;
    height: 30%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url(./images/content-writing.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 2vh;
  }
  .image-three{
    height: 50%;
    background-image: url(./images/content-writing.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 5px;

    @media screen and (max-width: 850px) {
      display: none;
    }
  }
  &:hover {
    border: 4px solid $primaryblue;
    margin: 18px;
  }
}

.contact {
  display: flex;
  background-color: $primaryblue;
  height: 100vh;

  
}
.right-side-contact {
  width: 50%;
  margin: 20px;
  background-image: url(./images/Fray-Harvey-LOGO-GnB.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 850px) {
    display: none;
  }
}

.left-side-contact {
  width: 40%;
  margin: 20px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 850px) {
    margin-top: 200px;
    justify-content: flex-start;
  }
}


.underline-contact {
  height: 1%;
  width: 100%;
  background-color: $primarygrey;
  margin-top: 20px;
  margin-bottom: 20px;
}

.email-box {
  color: $primarygrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .email-icon {
    background-image: url(./images/email-light.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
  }
  a {
    margin-left: 20px;
    text-decoration: none;
    color: $primarygrey;
  }
}

.phone-box {
  color: $primarygrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .phone-icon {
    background-image: url(./images/phone-light.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin-left: -5px;
  }
  a {
    margin-left: 20px;
    text-decoration: none;
    color: $primarygrey;
  }
}